$(function() {
    if($('#training').length) {
        // Functions scroll to target section -------------- ->
        function sectionScrolling() {
            var anchor = window.location.hash;
            if(anchor !== '') {
                $('html, body').animate({ scrollTop: $(anchor).offset().top }, 500);
            }

            $('aside.aside > ul > li > a', 'main.main').click(function(event) {           
                $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top }, 500);
            });
        }

        sectionScrolling();
        // End functions scroll to target section ---------- -<

        // Functions aside list fixed ---------------------- ->
        var aside_list = $('aside.aside > ul', 'main.main'),
            aside_list_top = aside_list.offset().top;

        function asideFixed(scroll_top) {
            if(scroll_top > aside_list_top) {
                if(!aside_list.hasClass('fixed')) {
                    aside_list.addClass('fixed');
                }
            }
            else {
                if(aside_list.hasClass('fixed')) {
                    aside_list.removeClass('fixed');
                }
            }
        }
        // End functions aside list fixed ------------------ -<

        // Functions on scroll ----------------------------- ->
        var scroll_top = $(document).scrollTop();
        asideFixed(scroll_top);
        
        $(window).scroll(function(){
            scroll_top = $(document).scrollTop();
            asideFixed(scroll_top);
        });
        // End functions on scroll ------------------------- -<
    }
});