$(function() {
    if($('.gallery').length) {
        $('.gallery').lightGallery();
    }

    var timer;
    $('nav#nav > ul > li.search-header', 'header#header').hover(function() {
        clearTimeout(timer);

        $(this).addClass('hover');
    }, function() {
        var $that = $(this);
        timer = setTimeout(function() {
            $that.removeClass('hover');
        }, 1000);
    });
});