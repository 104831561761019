$(function() {
    if($('#home').length) {
        // Functions load Google Maps ---------------------- ->
        function initMap() {
            for(var i = 0, len = mapsLatLng.length; i < len; i++) {
                var map = new google.maps.Map(document.getElementById('google-maps-' + (i + 1)), {
                    center: mapsLatLng[i],
                    zoom: 18,
                    mapTypeControl: false,
                    streetViewControl: false
                });

                var marker = new google.maps.Marker({
                    position: mapsLatLng[i],
                    map: map
                });
            }
        }

        initMap();
        // End functions load Google Maps ------------------ -<
    }
});